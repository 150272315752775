import React, { useContext, useEffect, useState } from "react";
import "../styles/home.scss";
import VoznjaLogo from "../voznjaLogo.png";
import VoznjaLogoSVG from "../5.svg";
import FullBelo from "../FullBeloV1.svg";
import VoznjaLottie from "../VoznjaLottie.json";
import { useNavigate } from "react-router";
import Lottie from "react-lottie";
import ReactCardFlip from "react-card-flip";
import { BiMenu } from "react-icons/bi";
interface Props {}

export function Pocetna(props: Props) {
  const [is1Flipped, set1Flipped] = useState<boolean>(false);
  const [is2Flipped, set2Flipped] = useState<boolean>(false);
  const [is3Flipped, set3Flipped] = useState<boolean>(false);
  const [isSmallMenu, setIsSmallMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: VoznjaLottie,
  };
  return (
    <>
      <div className="pocetnaHeader">
        <img src={FullBelo} alt="voznja" style={{ marginLeft: "10px" }} />
        <div className="middleLinks">
          <p
            onClick={() => {
              const f = document.getElementById("jumbo");
              f?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Početna
          </p>
          <p
            onClick={() => {
              const f = document.getElementById("funkcije");
              f?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Funkcije
          </p>
          <p
            onClick={() => {
              const f = document.getElementById("kontakt");
              f?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Kontakt
          </p>
          <p
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </p>
        </div>
        <button
          className="burger"
          onClick={() => {
            setIsSmallMenu(!isSmallMenu);
          }}
        >
          <BiMenu color="white" size={50} />
        </button>
        <div
          className="smallMenu"
          style={{
            left: isSmallMenu ? "0" : "-100vw",
          }}
        >
          <p
            onClick={() => {
              const f = document.getElementById("jumbo");
              f?.scrollIntoView({ behavior: "smooth" });
              setIsSmallMenu(false);
            }}
          >
            Početna
          </p>
          <p
            onClick={() => {
              const f = document.getElementById("funkcije");
              f?.scrollIntoView({ behavior: "smooth" });
              setIsSmallMenu(false);
            }}
          >
            Funkcije
          </p>
          <p
            onClick={() => {
              const f = document.getElementById("kontakt");
              f?.scrollIntoView({ behavior: "smooth" });
              setIsSmallMenu(false);
            }}
          >
            Kontakt
          </p>
          <p
            onClick={() => {
              navigate("/login");
              setIsSmallMenu(false);
            }}
          >
            Login
          </p>
        </div>
      </div>
      <div className="pocetnaJumbo" id="jumbo">
        <div className="jumboText">
          <h1>Unapredite Vašu auto školu!</h1>
          {/* <ul>
            <li>Zakazivanje termina putem aplikacije</li>
            <li>Interaktivni kvizovi</li>
            <li>Teorijski testovi</li>
            <li>Još mnogo toga...</li>
          </ul> */}
          <h2>
            Mesec dana <b>POTPUNO BESPLATNO</b>
          </h2>
          <h2>Bez ikakvih obaveza!</h2>
          <div className="jumboButtons">
            <button
              onClick={() => {
                const f = document.getElementById("funkcije");
                f?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Šta nudimo?
            </button>
            <button
              onClick={() => {
                const f = document.getElementById("kontakt");
                f?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Kontakt
            </button>
          </div>
        </div>
        <Lottie
          height={window.innerWidth > 768 ? 500 : 300}
          width={window.innerWidth > 768 ? 500 : 300}
          options={defaultOptions}
          style={{ margin: "0" }}
        />
      </div>
      <div className="pocetnaFunkcije" id={"funkcije"}>
        <h1>FUNKCIJE APLIKACIJE</h1>

        <h3 className="funkcijeh3">
          Naša aplikacija nudi veliki broj funkcija svima u okviru auto škole -
          instruktorima, kandidatima, predavačima i administraciji.
        </h3>
        <h3 className="funkcijeh3">
          GARANTUJEMO da će naš sistem zakazivanja termina olakšati i pospešiti
          funkcionisanje Vaše auto škole.
        </h3>

        <div className="funkcijeBox">
          <ReactCardFlip isFlipped={is1Flipped} flipDirection="horizontal">
            <div
              className="frontSide"
              onClick={() => {
                set1Flipped(true);
              }}
            >
              <h3>ZA INSTRUKTORE</h3>
              <p>Klik za više...</p>
            </div>
            <div
              className="backSide"
              onClick={() => {
                set1Flipped(false);
              }}
            >
              <ul>
                <li>Zakazivanje termina putem aplikacije</li>
                <li>Uvid u kandidate, njihov napredak</li>
                <li>Obavestavanje kandidata kada se oslobodi termin</li>
                <li>Obavestavanje kada se zakaze termin</li>
                <li>Još mnogo toga...</li>
              </ul>
            </div>
          </ReactCardFlip>
          <ReactCardFlip isFlipped={is2Flipped} flipDirection="horizontal">
            <div
              className="frontSide"
              onClick={() => {
                set2Flipped(true);
              }}
            >
              <h3>ZA KANDIDATE</h3>
              <p>Klik za više...</p>
            </div>
            <div
              className="backSide"
              onClick={() => {
                set2Flipped(false);
              }}
            >
              <ul>
                <li>Zakazivanje termina putem aplikacije</li>
                <li>
                  Uvid u zakazana predavanja teorije, kao i zakazane časove
                  vožnje
                </li>
                <li>Testiranje svog znanja na testovima</li>
                <li>Podsetnik pred zakazane časove</li>
                <li>Obaveštenje o oslobođenim terminima</li>
                <li>Još mnogo toga...</li>
              </ul>
            </div>
          </ReactCardFlip>
          <ReactCardFlip isFlipped={is3Flipped} flipDirection="horizontal">
            <div
              className="frontSide"
              onClick={() => {
                set3Flipped(true);
              }}
            >
              <h3>ZA PREDAVAČE</h3>
              <p>Klik za više...</p>
            </div>
            <div
              className="backSide"
              onClick={() => {
                set3Flipped(false);
              }}
            >
              <ul>
                <li>Zakazivanje predavanja putem aplikacije</li>
                <li>Uvid u kandidate, njihov napredak</li>
                <li>Kreiranje testova za proveru znanja</li>
                <li>Interaktivni kvizovi u toku predavanja</li>
                <li>Još mnogo toga...</li>
              </ul>
            </div>
          </ReactCardFlip>
        </div>
      </div>

      <div className="pocetnaKontakt" id={"kontakt"}>
        <h1>KONTAKT</h1>
        <div className="box">
          <div className="boxText">
            <h3>
              Postavite nam pitanje! Rado ćemo Vam dati sve potrebne
              informacije.
            </h3>
            <p>
              Telefon:{" "}
              <a id="linkic" href="tel:+381640004788">
                064 000 47 88
              </a>
            </p>
            <p>Email: office@voznja.rs</p>
          </div>
          {/* <div className="kontaktBox">
            <label>Ime</label>
            <input placeholder="Ime Prezime" type="text" name="ime" id="ime" />
            <label>Telefon</label>
            <input type="text" placeholder="Broj telefona" />
            <label>Auto škola</label>
            <input type="text" placeholder="Ime Vaše auto škole" />
            <label>Poruka</label>
            <textarea placeholder="Sadržaj poruke"></textarea>
            <button>Posalji</button>
          </div> */}
        </div>
      </div>
    </>
  );
}
